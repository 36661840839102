.container-footer {
    background-color: var(--wine);
    padding: 50px 20px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  
  .footer_logo_numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .footer_logo {
    display: flex;
    justify-content: center;
    margin-left: 20px;
  }
  
  .footer_logo img {
    width: 120px;
    height: auto;
  }
  
  .footer_numbers {
    display: flex;
    gap: 40px;
  }
  
  .footer_numbers div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer_number {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .footer_contacto h6,
  .footer_contacto p {
    margin: 5px 0;
  }
  
  .footer_contacto a {
    text-decoration: none;
    color: white;
    transition: color 0.3s;
  }
  
  .footer_contacto a:hover {
    color: var(--gold);
  }
  
  .container-footer .second-row p {
    color: white;
    padding-top: 10px;
  }
  
  .footer_sociales {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer_sociales a {
    color: white;
    font-size: 24px;
    transition: color 0.3s, transform 0.3s;
  }
  
  .footer_sociales a:hover {
    color: var(--gold);
    transform: translateY(-3px);
  }
  
  .hr-gob {
    border-top: 1px solid #ffffff;
    margin: 20px 0;
  }
  
  @media (max-width: 576px) {
    .footer_logo_numbers {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .footer_numbers {
      flex-direction: column;
      gap: 20px;
    }
  
    .footer_logo {
      margin: 0;
    }
  
    .footer_logo img {
      width: 150px;
    }
  }
  