#banner-principal{
    padding-top: 30px;
}

#banner-principal .img-carousel{
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#banner-principal .search {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease; /* Smooth transition for the search bar */
}

#banner-principal .search input {
    min-width: 500px;
    text-align: center;
    border: 0px;
    padding: 10px 30px;
    font-size: 15px;
    box-shadow: 0 3px 3px #757575;
    border-radius: 24px;
    transition: all 0.3s ease; /* Smooth transition for hover/focus effects */
}

#banner-principal .search input:focus,
#banner-principal .search input:active {
    outline: none;
    box-shadow: 0 0 0 2px var(--pink); /* Highlight border */
    min-width: 550px; /* Increase width on focus */
}

#banner-principal .search .icon-search {
    position: absolute;
    margin-top: 12px;
    margin-left: 12px;
    color: #757575;
    font-size: 18px;
}

#banner-principal .search .icon-search:hover {
    cursor: pointer;
}

@media (max-width: 576px){
    #banner-principal .search input {
        min-width: 350px;
    }
    #banner-principal .search input:focus,
    #banner-principal .search input:active {
        min-width: 360px; /* Increase width on focus for smaller screens */
    }
}
