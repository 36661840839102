.banner-section {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 50px auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.banner-item {
    width: 100%; /* La imagen ocupa el 100% del ancho del contenedor */
    height: auto; /* Ajusta la altura automáticamente */
    display: none; /* Oculta todas las imágenes inicialmente */
    transition: opacity 2s ease-in-out, transform 0.6s ease; /* Transición suave para opacidad y zoom */
}

.banner-item.active {
    display: block; /* Muestra solo la imagen activa */
    opacity: 1;
}

.banner-item:hover {
    transform: scale(1.05); /* Aplica el efecto de zoom suave */
    filter: brightness(1.05); /* Aumenta ligeramente el brillo en el hover */
    cursor: pointer;
}

/* Estilos responsive */
@media (max-width: 768px) {
    .banner-section {
        border-radius: 5px; /* Reducción de radio en pantallas más pequeñas */
    }

    .banner-item:hover {
        transform: none; /* Desactiva el zoom en pantallas más pequeñas */
        filter: none; /* Sin filtro adicional en móviles */
    }
}

@media (max-width: 480px) {
    .banner-section {
        border-radius: 5px; /* Reducción de radio en pantallas muy pequeñas */
    }

    .banner-item:hover {
        transform: none; /* Sin zoom en móviles */
        filter: none; /* Sin filtro adicional en móviles */
    }
}
