/* Contenedor principal */
.ligas-container {
    padding: 20px;
    background-color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Título */
  .ligas-title {
    text-align: left;
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--wine);
    margin-bottom: 15px;
  }
  
  /* Grid de Ligas */
  .ligas-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas */
    gap: 10px; /* Menos espacio entre elementos */
  }
  
  /* Elemento de la lista */
  .ligas-item {
    background-color: #f9f9f9;
    border: 1px solid var(--gold);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    padding: 10px; /* Reduce el padding */
  }
  
  .ligas-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Enlace de Liga */
  .ligas-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
  
  /* Icono de Liga */
  .ligas-icon {
    width: 40px; /* Tamaño más pequeño */
    height: 40px;
    margin-right: 10px; /* Menos margen */
    border-radius: 5px;
    object-fit: cover;
  }
  
  /* Información de Liga */
  .ligas-info {
    flex: 1;
  }
  
  /* Nombre de Liga */
  .ligas-text {
    font-size: 0.9rem; /* Fuente más pequeña */
    font-weight: 600;
    color: var(--wine);
    margin-bottom: 2px; /* Reduce margen inferior */
  }
  
  /* Descripción de Liga */
  .ligas-description {
    font-size: 0.8rem; /* Fuente más pequeña */
    color: #666;
    margin: 0;
  }
  
  /* Media Queries para móviles */
  @media (max-width: 768px) {
    .ligas-grid {
      grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
    }
  }
  