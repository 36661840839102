/* Contenedor principal */
.indicadores-container {
  padding: 10px 20px;
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-top: 40px;
  border-radius: 12px;
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
  opacity: 0;  /* Para el efecto de aparición */
  transform: translateY(30px);  /* Para el efecto de aparición */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Animación */
}

/* Clase para cuando es visible */
.indicadores-container.visible {
  opacity: 1;  /* Aparece */
  transform: translateY(0);  /* Vuelve a la posición normal */
}

/* Descripción */
.indicadores-container p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 30px;
  max-width: 800px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0 auto;
}

/* Contenedor de indicadores en línea */
.indicadores-linea-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 40px 0;
  padding-top: 40px;
  gap: 40px;
}

/* Indicador individual */
.indicador-punto {
  display: flex;
  flex-direction: row; /* Alineación horizontal */
  align-items: center;
  text-align: left; /* Texto alineado a la izquierda */
  position: relative;
  max-width: 30%;
  flex-grow: 1;
}

/* Número del indicador destacado */
.numero {
  font-size: 2.1rem;
  font-weight: 700;
  color: var(--wine);
  background: linear-gradient(to right, var(--gold), #ddc9a3);  /* Degradado de oro a color suave */
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 20px; /* Separación del texto */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  flex-shrink: 0; /* Evita que el número cambie de tamaño */
}

/* Descripción del indicador */
.descripcion {
  font-size: 1rem;
  color: #444;
  line-height: 1.6;
  max-width: 100%; /* Más espacio para el texto */
}

/* Media queries para dispositivos móviles */
@media (max-width: 768px) {
  .indicadores-linea-horizontal {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }

  .indicador-punto {
    max-width: 100%; /* Un solo indicador por fila */
    flex-direction: column; /* Cambio de disposición en móvil */
    text-align: center;
  }

  .numero {
    margin: 0 auto 15px; /* Centrado y espaciado */
  }

  .descripcion {
    text-align: center; /* Centrar el texto */
  }
}
