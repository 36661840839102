#facebook_page_container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto 50px auto;
    padding: 0;  /* Remueve el padding adicional */
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

#facebook_page_container .fb-page {
    width: 100%;
    min-height: 500px;  /* Cambiado de height a min-height */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;  /* Asegura que el contenido no se desborde */
}

#facebook_page_container .fb-page iframe {
    width: 100% !important;
    height: 100% !important;
    display: block;  /* Asegura que el iframe ocupe todo el espacio */
}

/* Media Queries para asegurar que el plugin se vea bien en dispositivos móviles */
@media (max-width: 576px) {
    #facebook_page_container {
        padding: 0;
    }
    #facebook_page_container .fb-page {
        min-height: 400px;  /* Cambiado de height a min-height */
    }
}
