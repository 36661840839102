/* src/components/MisionVisionModal/misionVisionModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    backdrop-filter: blur(5px);
  }
  
  .modal-container {
    background: linear-gradient(135deg, #ffffff, #f7f7f7);
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    width: 95%;
    max-width: 650px;
    position: relative;
    animation: fadeInUp 0.4s ease-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--wine);
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 2rem;
    color: var(--pink);
    font-family: Montserrat-Bold;
  }
  
  .close-icon {
    cursor: pointer;
    font-size: 2rem; /* Tamaño más grande para ser más visible */
    color: var(--wine);
    transition: color 0.3s, transform 0.3s;
  }
  
  .close-icon:hover {
    color: var(--wine);
    transform: rotate(90deg);
  }
  
  /* Estilos para las pestañas */
  .modal-tabs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
    background-color: #f1f1f1;
    border-radius: 12px;
    padding: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tab-button {
    flex: 1;
    background-color: transparent;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-size: 1rem;
    color: var(--wine);
    font-family: Montserrat-Bold;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    border-radius: 8px;
  }
  
  .tab-button.active {
    color: white;
    background-color: var(--pink);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tab-button:not(.active):hover {
    background-color: #e0e0e0;
    color: var(--wine);
  }
  
  .modal-content {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
    max-height: 400px;
    overflow-y: auto;
    padding: 15px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background-color: var(--wine);
    border-radius: 10px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  
  /* Botón de cerrar en el pie del modal */
  .modal-footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .close-button {
    background-color: var(--wine);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .close-button:hover {
    background-color: var(--pink);
    transform: translateY(-2px);
  }
  