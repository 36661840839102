/* Contenedor principal */
.rubros-container {
    padding: 40px 60px;
    background-color: #ffffff; /* Fondo suave y profesional */
    font-family: 'Montserrat', sans-serif; /* Usa la fuente Montserrat */
    color: #333;
  }
  
  /* Título */
  .rubros-title {
    text-align: left;
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--wine); /* Color consistente */
    margin-bottom: 5px;
    margin-left: 0;
    font-family: 'Montserrat-Bold'; /* Usar Montserrat-Bold */
  }
  
  /* Divisor */
  .rubros-divider {
    width: 80px;
    height: 3px;
    background-color: var(--wine); /* Azul consistente con el tema */
    margin: 10px 0 30px 0;
    border: 100px;
  }
  
  /* Grid moderno */
  .rubros-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 40px;
    margin-top: 30px;
  }
  
  /* Tarjeta de rubro */
  .rubro-card {
    background: linear-gradient(to right, var(--wine), var(--pink)); /* Degradado de izquierda a derecha */
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-decoration: none;
    color: inherit;
    position: relative;
    border: 1px solid var(--gold); /* Borde con el color dorado */
  }
  
  .rubro-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  /* Ícono en la tarjeta */
  .rubro-icon {
    background-color: var(--gold);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .rubro-icon img {
    width: 50px;
    height: 44px;
    border-radius: 0%; /* Mantener redondeado */
  }
  
  /* Nombre de rubro */
  .rubro-name {
    font-size: 1.3rem;
    margin-top: 10px;
    font-weight: 600;
    color: #ffffff; /* Texto en color blanco para mayor contraste */
  }
  
  /* Tooltip de rubro */
  .rubro-tooltip {
    position: fixed;
    background-color: #ffffff;
    padding: 15px 20px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    z-index: 10;
    max-width: 300px;
    pointer-events: none;
    border-radius: 10px;
    transition: left 0.1s ease, top 0.1s ease;
    font-family: 'Arial', sans-serif;
    font-size: 0.95rem;
  }
  
  .rubro-tooltip p {
    margin: 0;
    font-size: 14px;
    color: #333;
    text-align: justify;
  }
  
  /* Media queries para dispositivos móviles */
  @media (max-width: 576px) {
    .rubros-title {
      font-size: 1.8rem;
    }
  
    .rubro-card {
      padding: 20px;
    }
  }
